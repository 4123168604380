@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
svg {
  display: flex;
  align-items: center;
}
p {
  @apply text-neutral-700;
}

h1 {
  @apply text-2xl sm:text-3xl font-bold text-black;
}
h2 {
  @apply text-xl font-bold text-black pb-2;
}
h3 {
  @apply text-lg font-semibold text-black pt-3 pb-1;
}
.ulRingRose {
  @apply list-image-ellipseRose ml-[15px];
  li {
    @apply text-neutral-700 pl-3;
  }
}
